import {
  Autocomplete,
  AutocompleteOption,
  Chip,
  ListItemContent,
  ListItemDecorator,
  Typography,
} from '@mui/joy';
import { lowerFirst } from 'lodash';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { useFormikContext } from 'formik';

import FormikCheckbox from '../../2.0/formik/FormikCheckbox';
import Row from '../../2.0/layout/Row';

import { AcceptIcon, CancelIcon, IndentationArrowIcon } from '@/shared/icons/Icons';
import { AcceptedInvoiceResponse } from '~/common/types/finance/inbound/hoa/inboundHoaInvoices';

type Props = {
  files?: {
    fileName: string;
    isPublic: boolean;
    teamsWithAccess: { id: string; name: string }[];
  }[];
  invoices?: AcceptedInvoiceResponse[];
  boardMemberRoles?: string[];
  accessOptions: { label: string; value: string }[];
  currentTeamId: string;
};

const RmeMembers = ['Accountant', 'President', 'MemberOfRME'];

const DocumentsAccessibilityForm: React.FC<Props> = ({
  accessOptions,
  files,
  currentTeamId,
  invoices,
  boardMemberRoles,
}) => {
  const { t } = useTranslation(['common', 'finance']);
  const { setFieldValue } = useFormikContext();
  return (
    <div>
      <div
        className={classNames('space-y-4 py-4', {
          'max-h-[50vh] overflow-auto': !!invoices?.values,
        })}
      >
        {files?.values &&
          files.map((file) => (
            <div key={file.fileName} className="grid grid-cols-[min-content_auto] gap-x-4">
              <span className="whitespace-nowrap font-medium">{t('fileName')}</span>
              <span className="truncate">{file.fileName}</span>
              <span className="font-medium">{t('accessibility')}</span>
              <span className="truncate">
                {file.teamsWithAccess.map((team) => team.name).join(', ')}
              </span>
              <span className="font-medium">{t('public')}</span>

              {file.isPublic ? (
                <span className="text-green-500">
                  <AcceptIcon />
                </span>
              ) : (
                <span className="text-red-500">
                  <CancelIcon />
                </span>
              )}
            </div>
          ))}
        {invoices?.values &&
          invoices.map((invoice) => (
            <div key={invoice.invoiceNumber} className="grid grid-cols-[min-content_auto] gap-x-4">
              <span className="whitespace-nowrap font-medium">
                {t('_inboundHoaInvoice.columns.invoiceNumber')}
              </span>
              <span className="truncate">{invoice.invoiceNumber}</span>
              <span className="font-medium">{t('accessibility')}</span>
              <span className="truncate">
                {invoice.rolesWithAccess !== 'None'
                  ? invoice.rolesWithAccess
                      .split(', ')
                      .map((role) => t(`finance:_roles.${lowerFirst(role)}`))
                      .join(', ')
                  : '--'}
              </span>
            </div>
          ))}
      </div>
      <div className="border-t py-4">
        <div className="mb-2 italic text-gray-400">
          {t('_case.forms.documentAccessibility.info')}
        </div>
        <Row>
          <Autocomplete
            className="w-full"
            options={accessOptions}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value ||
              (value.value === 'BoardOfCoOwnership' && RmeMembers.includes(option.value))
            }
            renderOption={(props, option) => (
              <AutocompleteOption key={option.value} {...props} className="p-2">
                {boardMemberRoles?.includes(option.value) && (
                  <ListItemDecorator>
                    <IndentationArrowIcon className="px-4" size="lg" />
                  </ListItemDecorator>
                )}
                <ListItemContent>
                  <Typography>{option.label}</Typography>
                </ListItemContent>
              </AutocompleteOption>
            )}
            getOptionDisabled={(option: { label: string; value: string }) =>
              option.value === currentTeamId
            }
            onChange={(_, options) => {
              setFieldValue(
                files?.values ? 'teamsWithAccess' : 'rolesWithAccess',
                options.map((option) => option.value),
              );
            }}
            multiple
            disableCloseOnSelect
            renderTags={(tags, getTagProps) =>
              tags.map((item, index) => {
                const { onClick, ...rest } = getTagProps({ index });

                return (
                  <Chip
                    color="neutral"
                    variant="soft"
                    endDecorator={<CancelIcon className="-mr-1 text-base" />}
                    onClick={item.value === currentTeamId ? undefined : onClick}
                    {...rest}
                  >
                    {item.label}
                  </Chip>
                );
              })
            }
          />
        </Row>

        {files?.values && (
          <Row>
            <FormikCheckbox name="isPublic" label={t('publicCheckboxInfo')} />
          </Row>
        )}
      </div>
    </div>
  );
};

export default DocumentsAccessibilityForm;
