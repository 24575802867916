import { useCallback, useMemo } from 'react';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const SNACKBAR_OPTIONS = {
  preventDuplicate: true,
};

const controlledTypes = {
  CREATE: 'create',
  DELETE: 'delete',
  UPDATE: 'update',
} as const;

const messageKeys = {
  error: {
    [controlledTypes.CREATE]: 'errors:failedToCreateType',
    [controlledTypes.UPDATE]: 'errors:failedToUpdateType',
    [controlledTypes.DELETE]: 'errors:failedToDeleteType',
  },
  success: {
    [controlledTypes.CREATE]: 'typeSuccessfullyAdded',
    [controlledTypes.UPDATE]: 'typeSuccessfullyUpdated',
    [controlledTypes.DELETE]: 'typeSuccessfullyRemoved',
  },
};

type ControlledType = (typeof controlledTypes)[keyof typeof controlledTypes];

type BaseOptions<TData, TError, TVariables, TContext> = {
  entityKey: string;
  mutateAsync: UseMutateAsyncFunction<TData, TError, TVariables, TContext>;
  onError?: (error: TError) => void;
  onSuccess?: (data: TData) => void;
};

type ControlledOptions = {
  actionKey: ControlledType;
};

type CustomOptions = {
  snackbar: {
    error: string;
    success: string;
  };
};

type Options<TData, TError, TVariables, TContext> = BaseOptions<
  TData,
  TError,
  TVariables,
  TContext
> &
  (ControlledOptions | CustomOptions);

const isControlled = (options: ControlledOptions | CustomOptions): options is ControlledOptions =>
  !!(options as ControlledOptions).actionKey;

const useMutateWithSnackbar = <TData, TError, TVariables, TContext>(
  options: Options<TData, TError, TVariables, TContext>,
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const snackbarKeys = useMemo(() => {
    if (!isControlled(options)) return options.snackbar;

    const { actionKey } = options;

    return {
      error: messageKeys.error[actionKey],
      success: messageKeys.success[actionKey],
    };
  }, [options]);

  const mutateWithSnackbar = useCallback(
    async (input: TVariables) => {
      const { entityKey, mutateAsync, onError, onSuccess } = options;

      try {
        const res = await mutateAsync(input);

        enqueueSnackbar(t(snackbarKeys.success, { type: t(entityKey) }), {
          ...SNACKBAR_OPTIONS,
          variant: 'success',
        });
        onSuccess?.(res);

        return [res, null];
      } catch (err) {
        enqueueSnackbar(t(snackbarKeys.error, { type: t(entityKey) }), {
          ...SNACKBAR_OPTIONS,
          variant: 'error',
        });
        onError?.(err as TError);

        return [null, err as TError];
      }
    },
    [enqueueSnackbar, options, snackbarKeys.error, snackbarKeys.success, t],
  );

  return mutateWithSnackbar;
};

export default useMutateWithSnackbar;
