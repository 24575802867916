import * as yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  ComposeEmailFormikValues,
  EmailFileResponse,
  SenderOrReceiver,
} from '@/types/email/EmailFormikValues';
import { FileManagerFile, isFileManagerFile, File as UploadedFile } from '@/types/documents';
import { EmailMessage } from '~/common/types/mailbox';
import { Nullable } from '~/common/types';
import { trpc } from '@/config/trpc';
import { useMailboxContext } from '../../store';

type InitialEmail = Omit<ComposeEmailFormikValues, 'attachments' | 'compressFiles'> & {
  attachments: (UploadedFile | FileManagerFile)[];
};

const getSubject = (replyEmail: Nullable<EmailMessage>, isForward: boolean, isRetry: boolean) => {
  if (!replyEmail) {
    return '';
  }

  const subject = replyEmail.subject || '';

  if (isRetry) {
    return subject;
  }

  const prefix = isForward ? 'FWD:' : 'RE:';

  return subject.toLowerCase().startsWith(prefix.toLowerCase()) ? subject : `${prefix} ${subject}`;
};

const getAttachments = (
  isForward: boolean,
  isRetry: boolean,
  replyEmail: Nullable<EmailMessage>,
  initialFiles: (UploadedFile | FileManagerFile | EmailFileResponse)[],
) => {
  if (isForward || isRetry) {
    return (
      replyEmail?.attachments
        .filter((file) => !file.isInline && ((file.url && file.fileId) || !file.url))
        .map((attachment) => ({
          file: { ...attachment, id: attachment.contentId },
          id: attachment.id,
          isCaseFile: false,
          isForwardAttachment: true,
          messageId: replyEmail.id,
        })) || []
    );
  }

  if (!initialFiles.length) {
    return [];
  }

  return initialFiles.map((initialFile) => {
    const id = isFileManagerFile(initialFile) ? initialFile.fileId : initialFile.id;

    return {
      file: initialFile,
      id,
      isEntityFile: true,
      isForwardAttachment: false,
    };
  });
};

const formatEmailContact = (contact: { email: string; name?: Nullable<string> }) =>
  contact.name ? `${contact.name} &lt;${contact.email}&gt;` : contact.email;

export const useGetTo = (
  replyEmail: Nullable<EmailMessage>,
  isReplyAll: boolean,
  isRetry: boolean,
  emailAccountAddress?: string,
) => {
  const from = replyEmail?.from?.[0];
  const to = replyEmail?.to || [];

  if (isRetry) {
    return to;
  }

  const { data: fromAddresses } = trpc.mail.searchAddresses.useQuery(
    { term: from?.email as string },
    { enabled: !!from?.email },
  );

  const { data: toAddresses } = trpc.mail.searchAddressesByMultiple.useQuery(
    {
      terms: to.map((t) => t.email),
    },
    { enabled: !!to.length },
  );

  const fromEmail =
    from && fromAddresses?.length
      ? fromAddresses.find(
          (address) => address.email === from.email && (!from.name || from.name === address.name),
        ) || from
      : from;
  const fromEmails = fromEmail ? [fromEmail] : [];

  if (!isReplyAll) {
    return fromEmails;
  }

  const filteredTo = to.map((t) => {
    const matchingAddresses = toAddresses?.find(
      (address) => address.email === t.email && (!address.name || address.name === t.name),
    );

    return matchingAddresses || t;
  });

  return [...fromEmails, ...filteredTo].filter(
    (account) => account.email.toLowerCase() !== emailAccountAddress.toLowerCase(),
  );
};

export const useGetReplyEmailMetadata = (replyEmail: Nullable<EmailMessage>) => {
  const { t } = useTranslation();

  if (!replyEmail) return '';

  const cc = (() => {
    if (!replyEmail.cc?.length) {
      return '';
    }

    const contacts = replyEmail.cc.map((ccContact) => formatEmailContact(ccContact)).join('; ');

    return `<b>Cc:</b> ${contacts}<br>`;
  })();

  const from = (() => {
    if (!replyEmail.from?.length) {
      return '';
    }

    const firstFrom = replyEmail.from[0];

    return `<b>${t('from')}:</b> ${formatEmailContact(firstFrom)}<br>`;
  })();

  const to = (() => {
    if (!replyEmail.to?.length) {
      return '';
    }

    const contacts = replyEmail.to.map((toContact) => formatEmailContact(toContact)).join('; ');

    return `<b>${t('to')}:</b> ${contacts}<br>`;
  })();

  const date = replyEmail.dateEpoch
    ? `<b>${t('sent')}:</b> ${moment
        .unix(replyEmail.dateEpoch)
        .format('dddd, MMM Do, YYYY HH:mm')}<br>`
    : '';
  const subject = `<b>${t('subject')}:</b> ${replyEmail.subject}<br>`;

  return `
      <font color="#000000" face="Calibri, sans-serif" style="font-size: 11pt;">
        ${from}
        ${date}
        ${to}
        ${cc}
        ${subject}
      </font>
      `;
};

export const useGetBody = (
  initialBody: string | undefined,
  replyEmail: Nullable<EmailMessage>,
  isRetry: boolean,
  linkId?: string,
) => {
  const { data: teamSettings, isLoading } = trpc.team.user.teamSettings.useQuery();
  const hasInlineContent = !!replyEmail?.attachments?.some((a) => !!a.isInline);

  const { data: base64Body, isFetching: isLoadingBase64Body } =
    trpc.mailbox.messages.getBodyWithInlineImages.useQuery(
      {
        asBase64: true,
        emailAccountLinkId: linkId as string,
        messageId: replyEmail?.id as string,
      },
      {
        enabled: hasInlineContent && !!linkId,
      },
    );

  const metadata = useGetReplyEmailMetadata(replyEmail);

  if (isLoading || isLoadingBase64Body) {
    return { body: initialBody, isLoading: true };
  }

  const body = base64Body || replyEmail?.body || '';

  if (isRetry) {
    return { body: initialBody, isLoading: false };
  }

  const signature = teamSettings?.emailSignature || '';
  const divider = '<hr style="width: 100%" />';
  const result = `${initialBody || '<p></p><p></p>'}${signature}${
    body ? divider : ''
  }${metadata}${body}`;

  return { body: result, isLoading: false };
};

export const useComposeEmailConfig = (email: InitialEmail) => {
  const { t } = useTranslation();

  const {
    state: { replyEmail, isReplyAll, isForward, isRetry, emailAccount },
  } = useMailboxContext();
  const { emailAddress: emailAccountAddress, linkId } = emailAccount ?? {};

  const { body, isLoading: bodyIsLoading } = useGetBody(email.body, replyEmail, isRetry, linkId);
  const to = useGetTo(replyEmail, isReplyAll, isRetry, emailAccountAddress);

  const emailSchema = yup.object({ display: yup.string(), emailAddress: yup.string() });

  const initialTo = (() => {
    if (isForward) return [];

    return email.to?.length ? email.to : to;
  })();

  return {
    bodyIsLoading,
    initialValues: {
      attachments: getAttachments(isForward, isRetry, replyEmail, email.attachments),
      bcc: email.bcc,
      body,
      cc: isReplyAll
        ? replyEmail?.cc?.map((contact) => ({
            email: contact.email,
            name: contact.name,
          })) || []
        : [],
      compressFiles: true,
      replyToMessageId: isForward ? null : replyEmail?.id || null,
      subject: email.subject || getSubject(replyEmail, isForward, isRetry),
      to: initialTo,
    },
    validationSchema: yup.object({
      attachments: yup.array(),
      bcc: yup.array().of(emailSchema),
      cc: yup.array().of(emailSchema),
      subject: yup
        .string()
        .nullable()
        .required(t('errors:fieldIsRequired'))
        .max(255, t('errors:fieldMaxLengthIsX', { max: 255 })),
      tasks: yup
        .array()
        .nullable()
        .test('taskDataShouldBeValid', (value) => (value && value[0] ? value[0].isValid : true)),
      to: yup
        .array()
        .of(emailSchema)
        .when('bcc', {
          is: (bcc: SenderOrReceiver[]) => !bcc.length,
          then: yup.array().of(emailSchema).min(1, t('errors:fieldIsRequired')),
        }),
    }),
  };
};
