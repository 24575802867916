import { useMutation } from '@tanstack/react-query';

import { Document } from '~/common/types/document/document';
import { EntityType } from '~/common/enums';

import createRestClient from '@/shared/api/restClient';

type UploadBatchDocumentRequest = {
  entityId?: string;
  entityType?: EntityType;
  files: File[];
  parentEntityId: string;
  parentEntityType: EntityType;
  storeUntil: string;
};

const client = createRestClient();

const useUploadBatchDocuments = () => {
  const { mutate, mutateAsync, isLoading } = useMutation(
    async ({
      entityId,
      entityType,
      files,
      parentEntityId,
      parentEntityType,
      storeUntil,
    }: UploadBatchDocumentRequest) => {
      const data = new FormData();

      if (entityId && entityType) {
        data.set('entityId', entityId);
        data.set('entityType', entityType);
      }

      files.forEach((file) => {
        data.append('files', file);
      });

      data.set('parentEntityId', parentEntityId);
      data.set('parentEntityType', parentEntityType);
      data.set('storeUntil', storeUntil);

      return client.postForm<Document>('/document', data);
    },
  );

  return {
    isUploadingDocuments: isLoading,
    uploadBatchDocuments: mutate,
    uploadBatchDocumentsAsync: mutateAsync,
  };
};

export default useUploadBatchDocuments;
