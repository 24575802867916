import { useMutation } from '@tanstack/react-query';

import { EntityType } from '~/common/enums';

import createRestClient from '@/shared/api/restClient';

type PersistDocumentRequest = {
  documentIds: string[];
  entityId: string;
  entityType: EntityType;
  parentEntityId: string;
};

const client = createRestClient();

const usePersistBatchDocuments = () => {
  const { mutate, mutateAsync, isLoading } = useMutation(async (req: PersistDocumentRequest) =>
    client.put('/document/persist', req),
  );

  return {
    isUpdatingDocumentsPersistence: isLoading,
    updateDocumentsPersistenceAsync: mutateAsync,
    updateDocumentsPersitence: mutate,
  };
};

export default usePersistBatchDocuments;
